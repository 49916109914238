// import Vue from 'vue';

import _ from 'lodash'
import {serialize} from "object-to-formdata";

import moment from 'moment';

Vue.filter('date', function (value, formatA = 'YYYY-MM-DD', formatB = 'YYYY-MM-DD') {
    // return moment(value, formatA).format(formatB);
    return moment(value).format(formatA);
});


Vue.component('pagination', require('laravel-vue-pagination'));

window._ = _;
window.serializer = serialize;

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.redirect = function (route) {
    // alert('redirect : ' + route);
    window.location.href = route;
};
window.reload = function () {
    // alert('reload');
    window.location.reload();
};

// window.Vue = require('vue');

Vue.prototype._ = _;

if (typeof useVue != "undefined") {
    new Vue({
        el: '#vApp',
        mixins: mixins
    });
}
